<template>
<div>
    <b-col md="12" class="py-2">
      <custom-input
        v-if="variables"
        class="my-1"
        id="custom-input-r1"
        :possibleValues="variables"
        v-model="agent.register_1"
        advanced
      >
        <template #label>
          {{ $t(`agent.list.${i18nKey}.register_1.title`) }}
          
          <span class="float-right text-right mx-25 text-secondary">{{ $t(`agent.list.${i18nKey}.register_1.description`) }}</span>
        </template>
      </custom-input>
        <div v-else class="mb-2">
          <b-skeleton class="mb-25" width="25%" height="12px" />
          <b-skeleton class="mb-25" width="100%" height="37px" />
          <b-skeleton class="mb-25" width="25%" height="14px" />
        </div>
    </b-col>

    <b-col md="12" class="my-2">
      <custom-input
        v-if="variables"
        class="my-1"
        id="custom-input-rd"
        :possibleValues="variables"
        v-model="agent.register_destiny"
        advanced
      >
        <template #label>
          {{ $t(`agent.list.${i18nKey}.register_destiny.title`) }}
          
          <span class="float-right text-right mx-25 text-secondary">{{ $t(`agent.list.${i18nKey}.register_destiny.description`) }}</span>
        </template>
      </custom-input>
        <div v-else class="mb-2">
          <b-skeleton class="mb-25" width="25%" height="12px" />
          <b-skeleton class="mb-25" width="100%" height="37px" />
          <b-skeleton class="mb-25" width="25%" height="14px" />
        </div>
    </b-col>

    <b-col md="12" class="d-flex justify-content-center mt-3">
      <div class="text-center">
        <p class="text-secondary m-1">{{ $t('common.terms.file') + " "+ $t('common.terms.before').toLowerCase() }}</p>
        <b-iconstack scale="1.5">
          <b-icon icon="file-text" scale="2"></b-icon>
          <b-icon icon="pencil-fill" scale="1" variant="danger" shift-h="" shift-v="13"></b-icon>
        </b-iconstack>
      </div>
      <div class="text-center">
        <b-icon icon="arrow-right-short" scale="2" class="mx-2 mt-4" variant="success"></b-icon>
      </div>
      
      <div class="text-center"> 
      <p class="text-secondary m-1">{{ $t(`agent.list.${i18nKey}.icon_seek`) }}</p>
      <b-iconstack scale="1" class="mx-1" shift-v="14" shift-h="-8">
        <feather-icon
          icon="SearchIcon"
          size="38"
          class="text-favorite"
        />
      </b-iconstack>
      </div>

      <div class="text-center">
        <b-icon icon="arrow-right-short" scale="2" class="mx-2 mt-4" variant="success"></b-icon>
      </div>

      <div class="text-center">
        <p class="text-secondary m-1">{{ $t('common.terms.file') + " "+ $t('common.terms.after').toLowerCase() }}</p>
        <b-iconstack scale="1.5">
          <b-icon icon="file-text" scale="2"></b-icon>
          <b-icon icon="pencil-fill" scale="1" variant="danger" shift-h="10" shift-v="-2"></b-icon>
        </b-iconstack>
      </div>

      <!-- <b-icon icon="file-text" scale="2" class="mx-1" variant="success"></b-icon>
      <b-icon icon="arrow-right-short" scale="2" class="mx-1"></b-icon>
      <b-icon icon="search" scale="2" flip-h class="mx-1"></b-icon>
      <b-icon icon="arrow-right-short" scale="2" class="mx-1"></b-icon>
      <b-icon icon="arrow-right-short" scale="2" class="mx-1"></b-icon> -->


    </b-col>

</div>

</template>

<script>
import { BLink, BSkeleton, BFormCheckbox, BButton, BContainer, BCol, BRow, BSidebar, BIcon, BIconstack } from "bootstrap-vue";
import { mapActions } from "vuex";
import { v4 as uuidv4 } from "uuid";
import DefaultAgent from "./../DefaultAgent"
import VariablesPanel from "@/views/pages/middleware/MiddlewareManagement/Components/VariablesPanel.vue";
import CustomInput from "@/views/pages/middleware/MiddlewareManagement/Components/CustomInput.vue";
import NormalizedAgent from "@/custom/class/NormalizedAgent.js";
import HelperTooltip from '@/layouts/components/HelperTooltip';
import VuePerfectScrollbar from 'vue-perfect-scrollbar';

import AgentsMixin from '../AgentsMixin'

export default {
  mixins: [AgentsMixin],

  components: {
    BButton,
    BContainer,
    BCol,
    BRow,
    BSidebar,
    VariablesPanel,
    CustomInput,
    BFormCheckbox,
    BIcon,
    BSkeleton,
    HelperTooltip,
    BLink,
    VuePerfectScrollbar,
    BIconstack,
  },
  props: {
    middlewareID: {
      type: Number,
      required: true,
    },
    agentID: {
      type: Number,
      default: undefined,
    },
    value: {
      type: NormalizedAgent,
      default: undefined
    },
    uuid: {
      type: String,
      default: uuidv4(),
    },
    width: {
      type: String,
      default: "40%"
    },
    agentBlockID: {
      type: [ Number, String ],
      default: 1,
    },
    variables:{
      type: Object,
      default: undefined
    }
  },
  data() {
    return {
      actionID: 35,
      i18nKey: 'file_seek',
      defaultAgent: {
        id: null,
        enum_agent_action_id: this.actionID,
        agent_block_id: this.agentBlockID,
        register_1: {
          source: 7,
          value: ""
        },
        register_2: {},
        register_destiny: {
          source: 10,
          value: ""
        },
        execution_order: 1,
        fatal_on_fail: true,
      },

      uuidMap: {},

      variant_map: null,
      
      isSaving: false,
    };
  },
  computed: {
    agent: {
      get() {
        return this.value
      },
      set(value) {
        this.$emit('input', value)
      }
    },
    toSaveRegs() {
      return {
        register_1: true,
        register_2: false,
        register_destiny: true,
      }
    },
  },
  mounted() {
    this.init();
  },
  methods: {
    ...mapActions(["insertAgent", "updateAgent", "getAgentByID"]),
    init() {
      DefaultAgent.fetchAgentByID(this)
    },
    save() {
      return DefaultAgent.save(this)
    },
    validate() {
      return DefaultAgent.validate(this)
    },
    getID(key) {
      if (this.uuidMap[key]) {
        return this.uuidMap[key];
      }

      const uuid = uuidv4();
      this.uuidMap[key] = uuid;

      return uuid;
    },
    toggleSidebar() {
      this.$root.$emit("bv::toggle::collapse", this.uuid);
    },
  },
};
</script>

<style lang="scss" scoped>

.sidebar-container{
  max-height: 97vh;
  overflow-y: auto !important;
}

.sidebar-fixed-header{
  position: sticky !important;
  top: 0;
  z-index: 2;
}

.sidebar-content{
  position: relative !important;
  padding-bottom: 20px;
  margin-bottom: 70px !important;
}


</style>